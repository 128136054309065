import React, {useState, useEffect, useContext} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import { Preferences } from '@capacitor/preferences';
import {AuthContext} from "../AuthProvider";
import apiService from "../services/api.service";
import {useTranslation} from "react-i18next";
import {EXTERNAL_LINKS} from "../config";

const Login = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const { jwt, setJwt } = useContext(AuthContext)
    const { t, i18n} = useTranslation();

    // If already logged-in, navigate to dashboard
    useEffect(() => {
        if (jwt) {
            let rootPath = '/';

            if (window.location.search) {
                rootPath += window.location.search;
            }

            navigate(rootPath);
        }
    }, [navigate, jwt]);

    const authenticate = async (e) => {
        e.preventDefault();
        setError('');
        setLoading(true);

        try {
            const data = await apiService.post('jwtAuth', {
                username: username,
                password: password,
            });

            if (data.token) {
                await Preferences.set({ key: 'jwt', value: data.token });
                setJwt(data.token);
                setIsAuthenticated(true);
            } else {
                setError(t('loginError'));
            }
        } catch (error) {
            setError(t('loginError'));
            throw new Error(`Authentication error: ${error}`)
        } finally {
            setLoading(false);
        }
    };

    // Notice the useEffect hook added here
    useEffect(() => {
        if (isAuthenticated) {
            let rootPath = '/';
            if (window.location.search) {
                rootPath += window.location.search;
            }
            navigate(rootPath, { replace: true });
        }
    }, [isAuthenticated, navigate]);


    return (
        <div className="page page--login">
            <div className="login-header">
                <img src="https://eisenhorn.com/wp-content/uploads/150310_Logo_Eisenhorn_weiss.png" className="login-header__logo" width="250"/>
            </div>
            <div className="login-content">
                <h1>{t('mike5Wochenplan')}</h1>
                <p className="login-content__intro">{t('loginMessage')}</p>
                <form onSubmit={authenticate} className={loading ? 'is-loading' : ''}>
                    <input type="text" onChange={e => setUsername(e.target.value)} placeholder={t('emailAdresse')} />
                    <input type="password" onChange={e => setPassword(e.target.value)} placeholder={t('passwort')} />
                    {error && <p className="error-message">{error}</p>}
                    <button type="submit" className="btn" disabled={loading}>{loading ? t('pleaseWait') : t('login')}</button>
                    <div className="password-reset">{t('passwordForgot')} <Link target="_blank" rel="noopener" className="password-reset__link" to={EXTERNAL_LINKS[i18n.language].passwordForgot}>{t('resetPasswordText')}</Link></div>
                </form>

                <div className="login-content__invite">
                    <p>
                        {t('noMike5Member')}
                        <br />
                        <a href="https://eisenhorn.com/shop/mike5-membership/" target="_blank" rel="noopener">{t('joinNow')}</a>
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Login;