import React, {useEffect, useContext} from 'react';
import {Link, useParams} from 'react-router-dom';
import {Context} from '../context'
import WorkoutInfo from "./WorkoutInfo";
import {formatSeconds} from "../helpers";
import TimerCircle from "./TimerCircle";
import infoIcon from '../assets/images/info_icon.svg'
import {useTranslation} from "react-i18next";

const WORKOUT_TYPES = {
    'big5': 'Big5',
    'assistance': 'Assistance',
    'core': 'Core',
}

const WorkoutDetail = () => {
    const {
        currentDay,
        activeWorkout,
        workouts,
        activeMike5Workout,
        workoutTimer,
        startWorkoutTimer,
        pauseWorkoutTimer,
        restartWorkout,
        setSelectedWorkoutType,
        setActiveWorkout,
        nextExercise,
        endWorkout,
        setExercises,
    } = useContext(Context)
    const {workoutSlug, mike5Workout} = useParams();
    const { t} = useTranslation();

    useEffect(() => {
        const isMike5Workout = workoutSlug === 'mike5';
        const isWorkoutRunning = workoutTimer.started && workoutTimer.timeLeftInSet > 0;
        const openedWorkoutIsCurrentWorkout = (workoutSlug === activeWorkout.slug || (isMike5Workout && activeWorkout.mike5Component === mike5Workout));
        const workoutTitle = isMike5Workout ? activeMike5Workout.slug : workoutSlug;

        if (isWorkoutRunning && openedWorkoutIsCurrentWorkout) {
            return;
        }

        setSelectedWorkoutType(workoutSlug);

        // Adjust currentDay if it's 6 or 0 (weekend)
        const adjustedCurrentDay = (currentDay === 6 || currentDay === 0 || currentDay === 7) ? 5 : currentDay;

        // Get workout components based on the workout type
        let workoutComponents;

        if (isMike5Workout && activeMike5Workout?.workoutComponents?.[mike5Workout]) {
            workoutComponents = activeMike5Workout.workoutComponents[mike5Workout];
        } else if (workouts?.[workoutSlug]?.workoutComponents) {
            workoutComponents = workouts[workoutSlug].workoutComponents;
        }

        // Return early if workout components are not found
        if (!workoutComponents) {
            return;
        }

        // Check if the workout is day-based
        const isDayBased = workouts?.[workoutSlug]?.workoutType === 'day-based' || isMike5Workout;

        // Get the exercises based on the day or directly from workout components
        const exercises = isDayBased
            ? workoutComponents.exercises[adjustedCurrentDay - 1]
            : workoutComponents.exercises;

        // Extract remaining workout data excluding workout components
        const { ...remainingWorkoutData } = workouts[workoutTitle] || {};

        // Create the active workout data object
        const activeWorkoutData = {
            ...remainingWorkoutData,
            interval: workoutComponents.interval,
            exercises: exercises,
            selectedExercises: exercises,
            hasContinuosTimer: remainingWorkoutData.hasContinuosTimer || workoutComponents.interval.sets?.length <= 0 || remainingWorkoutData.mike5Component === 'core',
        };

        // Add mike5Component to active workout data if applicable
        if (isMike5Workout && mike5Workout?.length) {
            activeWorkoutData.mike5Component = mike5Workout;
        }

        // Set the active workout
        setActiveWorkout(activeWorkoutData);
    }, [
        activeMike5Workout?.workoutComponents,
        currentDay,
        mike5Workout,
        setActiveWorkout,
        setSelectedWorkoutType,
        workoutSlug,
        workouts,
    ]);

    if (!activeWorkout || !activeWorkout.selectedExercises?.length) {
        return <div>{t('noWorkoutFound')}</div>;
    }

    let currentIntensity;
    let intensityText;

    if (workoutTimer?.currentPhase === 'Work' || workoutTimer?.currentPhase === 'Get Ready') {
        currentIntensity = workoutTimer?.sets?.[workoutTimer.currentSet - 1]?.['intensity'] ?? 0;
    } else if (workoutTimer?.currentPhase === 'Rest' && workoutTimer.currentSet < workoutTimer?.sets?.length) {
        // For 'rest' phase, show the intensity of the next set, if it exists
        currentIntensity = workoutTimer?.sets?.[workoutTimer.currentSet]?.['intensity'] ?? 0;
    } else {
        currentIntensity = 0;
    }

    let colorIntensity = Math.floor(currentIntensity / 10) * 10;
    colorIntensity = colorIntensity > 100 ? 100 : colorIntensity;
    const activeDivsCount = Math.round(currentIntensity / 5);

    const intensityItems = new Array(20).fill().map((_, index) =>
        <div key={index} className={`workout-detail__exercise-timer-itensity-item`} style={ { backgroundColor: index < activeDivsCount ? `var(--color-intensity-${colorIntensity})` : 'rgba(0,0,0,0.15)' } }></div>
    );

    if (workoutTimer?.currentPhase === 'Work' || workoutTimer?.currentPhase === 'Get Ready') {
        intensityText = workoutTimer?.sets?.[workoutTimer.currentSet - 1]?.['intensityText'];
    } else if (workoutTimer?.currentPhase === 'Rest' && workoutTimer.currentSet < workoutTimer?.sets?.length) {
        // For 'rest' phase, show the intensity text of the next set, if it exists
        intensityText = workoutTimer?.sets?.[workoutTimer.currentSet]?.['intensityText'];
    }

    const currentExerciseIndex = workoutTimer.currentExercise;

    const getRestInstructions = (powerLevel, currentPhase) => {
        if (currentPhase !== "Rest") return '';

        if (powerLevel > 0) {
            return `Steigere deine Kraftstufe um ${powerLevel} für das nächste Set.`;
        } else if (powerLevel < 0) {
            return `Verringere deine Kraftstufe um ${Math.abs(powerLevel)} für das nächste Set.`;
        } else {
            return 'Bleib bei deiner momentanen Kraftstufe für das nächste Set.';
        }
    }

    return (
        <div className="workout-detail">
            <div className="workout-detail__exercise-meta-wrapper">
                { activeWorkout && activeWorkout.selectedExercises && activeWorkout.selectedExercises[currentExerciseIndex] &&
                    <>
                        <div className="workout-detail__meta">{WORKOUT_TYPES[mike5Workout]}</div>
                        <div className="workout-detail__exercise-name">{activeWorkout.selectedExercises[currentExerciseIndex].title}</div>
                        <div className="workout-detail__exercise-image"><img
                            src={activeWorkout.selectedExercises[currentExerciseIndex]['imageLink']} alt="" width="400" height="400" />
                        </div>
                    </>
                }
            </div>

            <div className="workout-detail__exercise-timer-wrapper">
                <div
                    className={`workout-detail__exercise-timer ${!workoutTimer.started ? 'is-disabled' : ''} ${workoutTimer.paused ? 'is-paused' : ''}`}>

                    {!workoutTimer.started &&
                        <WorkoutInfo workout={activeWorkout} workoutTimer={workoutTimer} workouts={workouts} activeMike5Workout={activeMike5Workout} startWorkoutTimer={startWorkoutTimer} setSelectedExercises={setExercises}/>
                    }

                    {workoutTimer.started &&
                        <>
                            <div className="workout-detail__exercise-timer-time-wrapper">
                                <div className="workout-detail__exercise-timer-content-wrapper">
                                    {! workoutTimer.hasContinuosTimer &&
                                        <div className="workout-detail__exercise-timer-interval">
                                            {workoutTimer.setsCount} Sets, {activeWorkout.interval.workTime}s
                                            Work, {activeWorkout.interval.restTime}s Rest
                                        </div>
                                    }
                                    <div
                                        className="workout-detail__exercise-timer-title">{t(workoutTimer.currentPhase)}</div>
                                    {workoutTimer.hasContinuosTimer &&
                                        <>
                                            <div
                                                className="workout-detail__exercise-timer-meta">{t('exercise')} {workoutTimer.currentExercise + 1}/{workoutTimer.exercisesCount}</div>
                                        </>
                                    }

                                    {! workoutTimer.hasContinuosTimer &&
                                        <div
                                            className="workout-detail__exercise-timer-meta workout-detail__exercise-timer-meta--sets">
                                            <div className='workout-detail__exercise-timer-sets'>
                                                {Array.from({ length: workoutTimer.setsCount }).map((_, index) => (
                                                    <div
                                                        key={index}
                                                        className={`workout-detail__exercise-timer-meta-set ${
                                                            index === workoutTimer.currentSet - 1
                                                                ? workoutTimer.currentPhase === 'Work'
                                                                    ? 'active'
                                                                    : workoutTimer.currentPhase === 'Rest'
                                                                        ? 'active-rest'
                                                                        : ''
                                                                : ''
                                                        }`}
                                                    >
                                                        <span>{index + 1}</span>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    }

                                    {workoutTimer.hasContinuosTimer &&
                                        <>
                                            <div
                                                className="workout-detail__exercise-timer-meta">Total {formatSeconds(workoutTimer.timeSpent === 0 ? 0 : workoutTimer.timeSpent - 1 )}</div>
                                        </>
                                    }
                                </div>

                                <TimerCircle
                                    size={80}
                                    strokeColor={workoutTimer.currentPhase === 'Work' ? '#E84E4E' : '#2D8130'}
                                    strokeWidth={5}
                                    strokeBgColor='#cccc'
                                />
                            </div>

                            {!workoutTimer.hasContinuosTimer &&
                                <div className="workout-detail__exercise-timer-intensity">
                                    <div className="workout-detail__exercise-timer-intensity-item-wrapper"
                                         style={{color: `var(--color-intensity-${colorIntensity})`}}>
                                        {intensityItems}
                                    </div>
                                    <div className="workout-detail__exercise-timer-intensity-info-wrapper">
                                        <Link to={'https://eisenhorn.com/definition-von-intensitaet/'} className="workout-detail__exercise-timer-intensity-title" target="_blank" rel="noopener">
                                            {t('intensity')} <img src={infoIcon} height="13"/>
                                        </Link>
                                        <div className="workout-detail__exercise-timer-intensity-text"
                                             style={{color: `var(--color-intensity-${colorIntensity})`}}>
                                            {intensityText ?? ''}
                                        </div>
                                    </div>
                                </div>
                            }

                            <div className="btn-group">
                                <button className="btn workout-detail__exercise-timer-break"
                                        onClick={pauseWorkoutTimer}>{!workoutTimer.paused ? t('pause') : t('fortsetzen')}</button>

                                {!workoutTimer.hasContinuosTimer &&
                                    <button className="btn btn--ghost workout-detail__workout-reset"
                                            onClick={restartWorkout}>{t('neustart')}</button>
                                }

                                {workoutTimer.hasContinuosTimer === true && workoutTimer.currentExercise < workoutTimer.exercisesCount - 1 &&
                                    <button className="btn btn--ghost workout-detail__workout-reset"
                                            onClick={nextExercise}>{t('weiter')}</button>}

                                {workoutTimer.exercisesCount >= 1 && activeWorkout.mike5Component !== 'big5' && workoutTimer.currentExercise === workoutTimer.exercisesCount - 1 &&
                                    <button className="btn btn--ghost workout-detail__workout-reset"
                                            onClick={endWorkout}>{t('beenden')}</button>}
                            </div>
                        </>
                    }
                </div>
            </div>
        </div>
    );
}

export default WorkoutDetail;