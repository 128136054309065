import React, {useContext, useEffect} from 'react';  // Import useEffect
import { useNavigate } from 'react-router-dom';
import {AuthContext} from "./AuthProvider";

const WithAuth = ({ Component }) => {
    const {jwt,} = useContext(AuthContext);
    const navigate = useNavigate();

    useEffect(() => {
        if (!jwt) {
            let loginPath = '/login';
            if (window.location.search) {
                loginPath += window.location.search;  // append the URL parameters to the login path
            }
            navigate(loginPath, { replace: true });
        }
    }, [jwt, navigate]);

    // If not authenticated, render null.
    if (!jwt) {
        return null;
    }
    return <Component />;
};

export default WithAuth;