import apiService from './api.service';

export const fetchUserData = async () => {
    try {
        return await apiService.get('user');
    } catch (error) {
        console.error('Failed to load user data: ', error);
        throw error;
    }
};

export const saveUsersWorkoutProgress = async (data) => {
    try {
        return await apiService.post('saveWorkoutProgress', data);
    } catch (error) {
        console.error('Failed to save workout progress: ', error);
        throw error;
    }
}