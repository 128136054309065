import {API_ROUTES, SITE_BASE_URL} from '../config';
import {Preferences} from "@capacitor/preferences";
import i18n from '../i18n';

const apiService = () => {
    const middlewares = [];

    const getApiRoute = (key, language = i18n.language) => {
        if (!API_ROUTES.hasOwnProperty(key)) {
            throw new Error(`Invalid API route: ${key}`);
        }
        return `${SITE_BASE_URL}/${API_ROUTES[key]}?lang=${language}`;
    };

    const use = (middleware) => {
        middlewares.push(middleware);
    };

    const request = async (url, options) => {
        const { value: token  } = await Preferences.get({ key: 'jwt' });

        const requestOptions = {
            ...options,
            headers: {
                ...options.headers,
                ...(token && { Authorization: `Bearer ${token}` }),
            },
        };

        // Apply middlewares
        const finalOptions = middlewares.reduce(
            (opts, middleware) => middleware(opts),
            requestOptions
        );

        const response = await fetch(url, finalOptions);

        if (response.status === 401 || response.status === 403) {
            window.dispatchEvent(new CustomEvent('invalidToken'));
        } else if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        return response.json();
    };

    const get = (endpoint, language) => request(getApiRoute(endpoint, language), { method: 'GET' });

    const post = (endpoint, data) => request(getApiRoute(endpoint), {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data),
    });

    return {
        use,
        get,
        post,
    };
};

export default apiService();