import {KeepAwake} from "@capacitor-community/keep-awake";

export const formatSeconds = (seconds) => {
    let minutes = Math.floor(seconds / 60);
    let remainingSeconds = seconds % 60;

    if (minutes < 10) {
        minutes = '0' + minutes;
    }
    if (remainingSeconds < 10) {
        remainingSeconds = '0' + remainingSeconds;
    }

    return `${minutes}:${remainingSeconds}`;
}

export const requestWakeLock = async () => {
    try {
        if( 'wakeLock' in navigator ) {
            await KeepAwake.keepAwake();
        }
    } catch (e) {
        console.error(e);
    }
};

export const releaseWakeLock = async () => {
    try {
        if( 'wakeLock' in navigator ) {
            await KeepAwake.allowSleep();
        }
    } catch (e) {
        console.error(e);
    }
};

export const getDateOfISOWeek = (day) => {
    const date = new Date();
    const currentDay = date.getDay();

    const diff = date.getDate() - currentDay + (currentDay == 0 ? (day-7):day); // adjust when day is sunday
    return new Date(date.setDate(diff));
}

export const dateAsWorkoutFormat = (date = new Date()) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}

export const dateToStringZeroTime = (date) => {
    date = new Date(date);
    date.setHours(0, 0, 0, 0);
    var yyyy = date.getFullYear();
    var mm = String(date.getMonth()+1).padStart(2, '0'); // January is 0!
    var dd = String(date.getDate()).padStart(2, '0');
    return yyyy + '-' + mm + '-' + dd;
}

export const dateToISOStringKeepTimezone = (dateObject = new Date()) => {
    let timezoneOffsetMinutes = dateObject.getTimezoneOffset();
    dateObject.setMinutes(dateObject.getMinutes() - timezoneOffsetMinutes);

    return dateObject.toISOString();
};

export const isEmptyObject = (obj) => {
    return Object.keys(obj).length === 0 && obj.constructor === Object;
}