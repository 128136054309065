import React, {createContext, useState, useEffect, useRef, useContext} from 'react';
import localforage from 'localforage';
import CryptoJS from "crypto-js";
import queryString from 'query-string';

// Services
import apiService from './services/api.service'

// Hooks
import useSfx from './hooks/useSfx';
import useWorkoutTimer from "./hooks/useWorkoutTimer";

// Context
import {AuthContext} from "./AuthProvider";
import {QueueContext} from "./QueueProvider";

// Utils
import { requestWakeLock, releaseWakeLock } from "./helpers";
import {useTranslation} from "react-i18next";


const Context = createContext();

const ContextProvider = ({ children }) => {
    const { t, i18n } = useTranslation();
    const date = new Date();
    const day = date.getDay() === 0 ? 7 : date.getDay();
    const [currentDate, setCurrentDate] = useState(date);
    const [currentDay, setCurrentDay] = useState(day);
    const [completedDays, setCompletedDays] = useState([]);
    const [workouts, setWorkouts] = useState([]);
    const [activeWorkout, setActiveWorkout] = useState({});
    const [activeMike5Workout, setActiveMike5Workout] = useState({});
    const [loading, setLoading] = useState(false);
    const [currentLanguage, setCurrentLanguage] = useState(i18n.language)

    const sfx = useSfx();

    const [selectedWorkoutType, setSelectedWorkoutType] = useState(null);
    const { jwt } = useContext(AuthContext);
    const { addToQueue } = useContext(QueueContext);
    const [userData, setUserData] = useState({});

    const [isPopupOpen, setPopupOpen] = useState(false);
    const [popupData, setPopupData] = useState({});

    const workoutsRef = useRef(); // Create a ref for workouts
    const activeMike5WorkoutRef = useRef(); // Create a ref for activeMike5Workout
    const workoutTimerRef = useRef(); // Create a ref for  workoutTimer

    const openPopup = (data) => {
        setPopupOpen(true);
        setPopupData(data);
    };

    const { workoutTimer, startWorkoutTimer, pauseWorkoutTimer, restartWorkout, nextExercise, endWorkout } = useWorkoutTimer(
        activeWorkout,
        activeMike5Workout,
        openPopup,
        selectedWorkoutType,
        addToQueue,
        setUserData,
        sfx,
        currentDate,
    );

    const closePopup = () => {
        setPopupOpen(false);
        setPopupData({});
    };

    // Update currentDay ensuring it's not a future day
    const handleSetCurrentDay = (day) => {
        setCurrentDay(day + 1);
    };

    // Add a new day to completedDays
    const handleSetCompletedDay = (day) => {
        setCompletedDays(prevDays => [...prevDays, day]);
    };

    const setExercises = (exercises) => {
        setActiveWorkout((prevState) => {
            return {
                ...prevState,
                exercises: exercises,
                selectedExercises: exercises.filter(exercise => exercise.isSelected),
            }
        })
    }

    useEffect(() => {
        workoutsRef.current = workouts;
        activeMike5WorkoutRef.current = activeMike5Workout;
        workoutTimerRef.current = workoutTimer;
    }, [workouts, activeMike5Workout, workoutTimer]);

    const changeLanguage = async (lng) => {
        await i18n.changeLanguage(lng);
        await localforage.setItem('language', lng);
        await setCurrentLanguage(lng);

        const cachedWorkouts = await localforage.getItem('workouts_' + lng);
        const cachedActiveMike5Workout = await localforage.getItem('activeMike5Workout_' + lng);

        if (cachedWorkouts && cachedActiveMike5Workout) {
            setWorkouts(cachedWorkouts);
            setActiveMike5Workout(cachedActiveMike5Workout);
        }
    };

    const fetchWorkouts = async () => {
        try {
            const cachedWorkouts = await localforage.getItem('workouts_' + i18n.language);
            const cachedActiveMike5Workout = await localforage.getItem('activeMike5Workout_' + i18n.language);

            const isWorkoutsEmpty = Object.keys(workoutsRef.current).length === 0;
            const isWorkoutTimerStarted = workoutTimerRef.current.started === false;
            const areCachedWorkoutsAvailable = cachedWorkouts && Object.keys(cachedWorkouts).length > 0;

            if (areCachedWorkoutsAvailable && isWorkoutsEmpty && isWorkoutTimerStarted) {
                setWorkouts(cachedWorkouts);
                setActiveMike5Workout(cachedActiveMike5Workout);
            }

            if (!areCachedWorkoutsAvailable) {
                setLoading(true);
            }

            const languages = ['en', 'de']; // Specify the languages you want to fetch

            for (const language of languages) {
                const previousHash = await localforage.getItem('workoutsHash_' + language);

                const data = await apiService.get('workouts', language);
                const currentHash = CryptoJS.SHA256(JSON.stringify(data)).toString();

                if (currentHash !== previousHash) {
                    await localforage.setItem('workoutsHash_' + language, currentHash);

                    const activeMike5 = Object.values(data).find(workout => workout.workoutType === 'mike5');
                    await localforage.setItem('workouts_' + language, data);
                    await localforage.setItem('activeMike5Workout_' + language, activeMike5);

                    if (language === i18n.language && !workoutTimerRef.current.started) {
                        setWorkouts(data);
                        setActiveMike5Workout(activeMike5);
                    }
                }
            }
        } catch (e) {
            throw new Error(`Failed to load workouts ${e}`);
        } finally {
            setLoading(false);
        }
    };

    const fetchUserData = async () => {
        const userData = await apiService.get('userData');
        setUserData(userData);
    }

    const showOnboardingPopup = async (forceShow = false) => {
        const welcomePopupRead = await localforage.getItem('welcomePopupRead');

        if (!welcomePopupRead || forceShow) {
            openPopup({
                type: 'slider',
                actions: [],
                lastAction: {
                  action: 'close',
                  title: t('startTraining'),
                },
                textAction: {
                    action: 'close',
                    title: t('skipTour'),
                },
                slides: [
                    {
                        title: t('onboardingWelcomeTitle'),
                        content: t('onboardingWelcome')
                    },
                    {
                        title: t('onboardingLessisMoreTitle'),
                        content: t('onboardingLessisMore')
                    },
                    {
                        title: t('onboardingAdditionalExercises'),
                        content: t('onboardingAdditionalExercisesContent')
                    },
                    {
                        title: t('onboardingOnboard'),
                        content: t('onboardingOnboardContent')
                    },
                    {
                        title: t('onboardingEfficientTraining'),
                        content: t('onboardingEfficientTrainingContent')
                    }
                ]
            });
        }
    }

    useEffect(() => {
        if (jwt) {
            showOnboardingPopup();

            const fetchAllData = async () => {
                const userData = await localforage.getItem('userData');
                setUserData(userData || {});

                // Fetch once to get the initial data
                await Promise.all([fetchUserData(), fetchWorkouts()]);
            };

            fetchAllData();

            // Then set up a timer to fetch every 2 minutes
            const fetchInterval = setInterval(() => {
                fetchWorkouts();
            }, 60000);

            // Clear interval on component unmount
            return () => {
                clearInterval(fetchInterval);
            }
        }
    }, [jwt, currentLanguage]);

    useEffect(() => {
        if(workouts !== null){
            // Get today's date
            let now = new Date();
            // Get today's day of the week (0 - Sunday, 1 - Monday, ..., 6 - Saturday)
            let today = now.getDay();
            // Adjust today to 1 for Monday, ..., 7 for Sunday
            today = today === 0 ? 7 : today;
            // Calculate the difference in days
            let deltaDays = currentDay - today;
            // Adjust the date
            now.setDate(now.getDate() + deltaDays);
            // Reset time part to avoid hours, minutes and seconds affecting our result
            now.setHours(0, 0, 0, 0);

            setCurrentDate(now);
        }
    }, [currentDay, workouts]);

    useEffect(() => {
        if(Object.keys(userData).length === 0) {
            return;
        }

        const storeUserData = async () => {
            await localforage.setItem('userData', userData);
        };

        storeUserData();
    }, [userData]);

    useEffect(() => {
        const getAndSetLanguage = async () => {
            const parsed = queryString.parse(window.location.search);
            const langParam = parsed.lang;

            let languageToSet = 'de'; // your default language code
            const savedLanguage = await localforage.getItem('language');

            if (langParam) {
                languageToSet = langParam;
            } else if (savedLanguage) {
                languageToSet = savedLanguage;
            }

            i18n.changeLanguage(languageToSet);
        };

        getAndSetLanguage();
    }, []);


    return (
        <Context.Provider value={{
            // User Data
            userData,

            // Day Navigation
            currentDate,
            currentDay,
            completedDays,
            handleSetCurrentDay,
            handleSetCompletedDay,

            // Workout
            workouts,
            activeWorkout,
            setActiveWorkout,
            activeMike5Workout,
            setCurrentDay: handleSetCurrentDay,
            setCompletedDay: handleSetCompletedDay,
            loading,
            workoutTimer,
            startWorkoutTimer,
            pauseWorkoutTimer,
            restartWorkout,
            nextExercise,
            endWorkout,
            selectedWorkoutType,
            setSelectedWorkoutType,
            setExercises,

            // Popup
            isPopupOpen,
            popupData,
            openPopup,
            closePopup,
            showOnboardingPopup,

            // Utility
            requestWakeLock,
            releaseWakeLock,
            changeLanguage,
        }}
        >
            {children}
        </Context.Provider>
    );
};

export { Context, ContextProvider };