import React, { createContext, useState, useEffect } from 'react';
import { Preferences } from '@capacitor/preferences';

export const AuthContext = createContext({});

const AuthProvider = ({children}) => {
    const [jwt, setJwt] = useState(null);

    useEffect(() => {
        const getToken = async () => {
            try {
                const { value } = await Preferences.get({ key: 'jwt' });
                setJwt(value);
            } catch(error) {
                console.error("Error retrieving token:", error);
            }
        };

        getToken();
    }, []);

    const logout = async () => {
        try {
            // Clear the token from the Preferences storage
            await Preferences.remove({ key: 'jwt' });
            // Clear the token from state
            setJwt(null);
        } catch(error) {
            console.error("Error during logout:", error);
        }
    };

    useEffect(() => {
        const handleInvalidToken = () => {
            logout();
        };

        window.addEventListener('invalidToken', handleInvalidToken);

        return () => {
            window.removeEventListener('invalidToken', handleInvalidToken);
        };
    }, []);

    return (
        <AuthContext.Provider value={{ jwt, setJwt, logout }}>
            {children}
        </AuthContext.Provider>
    );
};

export default AuthProvider;