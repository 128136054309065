import React from 'react';
import { useNavigate } from 'react-router-dom';
import closeIcon from '../assets/images/close-black.svg';


const PopupModal = ({ title, content, actions, textAction, showClose, closePopup }) => {
    const navigate = useNavigate();

    const handleAction = (action) => {
        if (action.navigate) {
            navigate(action.navigate);
        } else if( action.onClick && typeof action.onClick === 'function') {
            action.onClick();
        }

        closePopup();
    };

    return (
        <div className="popup-modal">
            <div className="popup-modal__content">
                {showClose && <button className="popup-modal__close" onClick={closePopup}><img height="14" src={closeIcon} alt=""/></button> }
                <h2>{title}</h2>
                <div dangerouslySetInnerHTML={{__html: content}}></div>
                <div className="popup-modal__actions btn-group">
                    {actions.map((action, index) => (
                        <button
                            className={`btn--small btn ${action.secondary ? 'btn--ghost' : '' }`}
                            key={index}
                            onClick={() => handleAction(action)}
                        >
                            {action.label}
                        </button>
                    ))}
                </div>
                {textAction &&
                    <div className="popup-modal__text-actions">
                        <button
                            className={`btn--text btn`}
                            onClick={() => handleAction(textAction)}>{textAction.label}</button>
                    </div>
                }
            </div>
        </div>
    );
};

export default PopupModal;