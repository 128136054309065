import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Context } from '../context'; // or the path to your context file
import { EXTERNAL_LINKS } from '../config';

const Dashboard = () => {
    const { releaseWakeLock } = useContext(Context);
    const { t, i18n } = useTranslation();

    releaseWakeLock();

    return (
        <div className="page page--dashboard">
            <div className='workout-list'>
                <div className='workout-list__wrapper'>
                    <h2 className='workout-list__title'>{t('mike5WeeklyPlan')}</h2>
                    <div className='workout-list__teaser-wrapper'>
                        <div className='workout-list__teaser workout-list__teaser--double workout-list__teaser--wotext'
                             style={{backgroundImage: `url("https://eisenhorn.com/wp-content/uploads/workout-mike5-uebungen-1024x569.jpg")`}}>
                            <Link to="/mike5" className='workout-list__workout'>
                                &nbsp;
                            </Link>
                        </div>
                    </div>
                </div>
                <div className='workout-list__wrapper'>
                    <h2 className='workout-list__title'>{t('exploreAllExercises')}</h2>
                    <div className='workout-list__teaser-wrapper'>
                        <div className='workout-list__teaser workout-list__teaser--double'
                             style={{backgroundImage: `url("https://eisenhorn.com/wp-content/uploads/Bildschirmfoto-2024-05-14-um-17.05.10.png")`}}>
                            <Link
                                to={EXTERNAL_LINKS[i18n.language].exerciseDatabase}
                                target="_blank" rel="noopener"
                                className='workout-list__workout'>
                                {t('exercisesLink')}
                            </Link>
                        </div>
                    </div>
                </div>
                <div className='workout-list__wrapper'>
                    <h2 className='workout-list__title'>{t('eisenhornKnowledge')}</h2>
                    <div className='workout-list__teaser-wrapper'>
                        <div className='workout-list__teaser workout-list__teaser--double'
                             style={{backgroundImage: `url()`}}>
                            <Link
                                to={EXTERNAL_LINKS[i18n.language].mike5Concept}
                                target="_blank" rel="noopener"
                                className='workout-list__workout'>
                                {t('trainingsConceptLink')}
                            </Link>
                        </div>

                        <div className='workout-list__teaser'
                             style={{backgroundImage: `url(https://eisenhorn.com/wp-content/uploads/2020/04/big-5-cross.jpg)`}}>
                            <Link to={EXTERNAL_LINKS[i18n.language].motivating}
                                  target="_blank" rel="noopener"
                                  className='workout-list__workout'>
                                <strong style={{fontWeight: 800, fontSize: '1.5em'}}>M</strong><br/>
                                {t('motivierend')}
                            </Link>
                        </div>

                        <div className='workout-list__teaser'
                             style={{backgroundImage: `url(https://eisenhorn.com/wp-content/uploads/230615-james-wood-2.jpg)`}}>
                            <Link to={EXTERNAL_LINKS[i18n.language].intensity}
                                  target="_blank" rel="noopener"
                                  className='workout-list__workout'>
                                <strong style={{fontWeight: 800, fontSize: '1.5em'}}>I</strong><br/>
                                {t('intensive')}
                            </Link>
                        </div>
                        <div className='workout-list__teaser'
                             style={{backgroundImage: `url(https://eisenhorn.com/wp-content/uploads/kurzundhartmitperiodisierung.png)`}}>
                            <Link to={EXTERNAL_LINKS[i18n.language].periodization} target="_blank"
                                  rel="noopener"
                                  className='workout-list__workout'>
                                <strong style={{fontWeight: 800, fontSize: '1.5em'}}>K</strong><br/>
                                {t('kurz')}
                            </Link>
                        </div>
                        <div className='workout-list__teaser'
                             style={{backgroundImage: `url(https://eisenhorn.com/wp-content/uploads/effizienziphone.png)`}}>
                            <Link to={EXTERNAL_LINKS[i18n.language].efficiency}
                                  target="_blank" rel="noopener"
                                  className='workout-list__workout'>
                                <strong style={{fontWeight: 800, fontSize: '1.5em'}}>E</strong><br/>
                                {t('effizient')}
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Dashboard;