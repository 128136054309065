import React, {useState, useContext, useEffect} from 'react';
import logo from '../assets/images/eisenhorn-logo.svg';
import chevronLeft from '../assets/images/chevron-left.svg';
import account from '../assets/images/account.svg';
import menuDots from '../assets/images/menu-dots.svg';
import closeIcon from '../assets/images/close.svg';
import {Link, useLocation, useParams, useNavigate} from 'react-router-dom';
import {Context} from "../context";
import {AuthContext} from "../AuthProvider";
import {useTranslation} from "react-i18next";
import { EXTERNAL_LINKS } from '../config';

function Header() {
    const location = useLocation();
    const params = useParams();
    const navigate = useNavigate();
    const {activeWorkout, workoutTimer, changeLanguage, showOnboardingPopup} = useContext(Context);
    const [navIsOpen, setNavIsOpen] = useState(false);
    const {logout} = useContext(AuthContext);
    const { t, i18n} = useTranslation();

    useEffect(() => {
        setNavIsOpen(false);
    }, [location.pathname]);

    useEffect(() => {
        function handleClickOutside(event) {
            const navigationEl = document.querySelector('.navigation');
            if (navigationEl && !navigationEl.contains(event.target)) {
                setNavIsOpen(false);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    if (location.pathname === '/login') {
        return null;
    }


    const menuRoutes = [
        {
            title: "Dashboard",
            href: "/",
        },
        {
            title: t('myAccountLink'),
            href: "https://eisenhorn.com/my-account/",
            newWindow: true,
        },
        {
            title: t('exerciseGuideLink'),
            href: (activeWorkout?.exercises && workoutTimer?.currentExercise < activeWorkout.exercises.length) ?
                activeWorkout.exercises[workoutTimer.currentExercise]?.link : '',
            newWindow: true,
            onlyWorkoutDetail: true,
        },
        {
            title: t('anleitungZumInterval'),
            href: activeWorkout?.interval?.link || '',
            newWindow: true,
            onlyWorkoutDetail: true,
        },
        {
            title: t('exercisesLink'),
            href: EXTERNAL_LINKS[i18n.language].exerciseDatabase,
            newWindow: true,
        },
        {
            title: 'MIKE5 Onboarding',
            action: () => showOnboardingPopup(true),
        },
    ]

    return (
        <header className="header">
            {/* Render the Chevron Left Icon only when not on home route */}
            <div className="flex-center">
                {location.pathname !== "/" && location.pathname !== "/login" && (
                    <img
                        src={chevronLeft}
                        className="icon header__back"
                        height="20"
                        onClick={() => navigate(-1)} // Navigate back to home route on clicking the icon
                    />
                )}
            </div>
            <img src={logo} className="header__logo" width="130" height="20" alt="EISENHORN Logo"
                 onClick={() => navigate("/")}/>


            {!navIsOpen &&
                <img src={menuDots} className="icon header__menu" height="18" onClick={() => setNavIsOpen(true)}/>
            }

            {navIsOpen &&
                <img src={closeIcon} className="icon header__menu" height="16" onClick={() => setNavIsOpen(false)}/>
            }

            <div className={`navigation ${navIsOpen ? 'is-open' : ''}`}>
                <ul>
                    {menuRoutes.map((route) => {
                        const {href, title, action, newWindow} = route;

                        if (route.onlyWorkoutDetail && !location.pathname.startsWith('/workout/')) {
                            return null;
                        }

                        return (
                            <li className={location.pathname === href ? 'is-active' : ''}>
                                {!newWindow && !action &&
                                    <Link to={href}>{title}</Link>
                                }

                                {newWindow &&
                                    <a
                                        href={href}
                                        className="flex items-center gap-1 hover:text-neutral-400 transition-all"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        {title}
                                    </a>
                                }

                                { action &&
                                    <button onClick={action}>{title}</button>
                                }
                            </li>
                        );
                    })}
                    <li onClick={logout}>{t('logout')}</li>
                </ul>

                <div className='header__language-switcher'>
                    <button
                        className={i18n.language === 'de' ? 'active' : ''}
                        onClick={() => changeLanguage("de")}>
                        Deutsch
                    </button>
                    <button
                        className={i18n.language === 'en' ? 'active' : ''}
                        onClick={() => changeLanguage("en")}>
                        English
                    </button>
                </div>
            </div>
        </header>
    );
}

export default Header;
 