import React, {useState} from 'react';
import { useNavigate } from 'react-router-dom';
import closeIcon from '../assets/images/close-black.svg';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import { useTranslation } from 'react-i18next';

import localforage from "localforage";

const SliderModal = ({ slides, actions, textAction, lastAction, closePopup }) => {
    const navigate = useNavigate();
    const [swiper, setSwiper] = useState(null);
    const {t} = useTranslation();

    const handleAction = async (action) => {
        if (action.navigate) {
            navigate(action.navigate);
        } else if( action.onClick && typeof action.onClick === 'function') {
            action.onClick();
        }

        await localforage.setItem('welcomePopupRead', true);

        closePopup();
    };

    return (
        <div className="popup-modal popup-modal--slider">
            <div className="popup-modal__content">
                <button className="popup-modal__close" onClick={handleAction}><img height="14" src={closeIcon} alt=""/></button>
                <Swiper
                    spaceBetween={50}
                    slidesPerView={1}
                    autoHeight={true}
                    pagination={{
                        dynamicBullets: true,
                        el: ".popup-modal__pagination"
                    }}
                    modules={[Pagination]}
                    onSlideChange={() => console.log('slide change')}
                    onSwiper={(swiper) => {
                        console.log(swiper);
                        setSwiper(swiper);
                    }}
                >
                    {slides.map(({title, content}, i) => (
                        <SwiperSlide>
                            <h2 className="left">{title}</h2>
                            <div dangerouslySetInnerHTML={{ __html: content }}></div>

                            {slides.length > 0 &&
                                <div className="popup-modal__actions btn-group">
                                    { i > 0 &&
                                        <button
                                            className={`btn--small btn btn--ghost`}
                                            onClick={() => swiper.slidePrev()}
                                        >
                                            {t('back')}
                                        </button>
                                    }
                                    { slides.length - 1 !== i &&
                                        <button
                                            className={`btn--small btn`}
                                            onClick={() => swiper.slideNext()}
                                        >
                                            {t('weiter')}
                                        </button>
                                    }

                                    {slides.length - 1 === i &&
                                        <button
                                            className={`btn--small btn`}
                                            onClick={handleAction}
                                        >
                                            {lastAction.title}
                                        </button>
                                    }
                                </div>
                            }

                            {textAction &&
                                <div className="popup-modal__text-actions">
                                    <button
                                        className={`btn--text btn`}
                                        onClick={textAction.action === 'close' ? handleAction : textAction.action}>{textAction.title}
                                    </button>
                                </div>
                            }
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
            <div className="popup-modal__pagination"></div>
        </div>
    );
};

export default SliderModal;