export const SITE_BASE_URL = process.env.REACT_APP_SITE_BASE_URL;

export const API_ROUTES = {
    jwtAuth : 'wp-json/jwt-auth/v1/token',
    workouts: 'wp-json/wp/v2/workouts',
    saveWorkoutProgress: 'wp-json/wp/v2/save-workout-progress',
    userData: 'wp-json/wp/v2/user-data'
}

export const EXTERNAL_LINKS = {
    'de': {
        exerciseDatabase: 'https://eisenhorn.com/eisenhorn-uebungen/',
        mike5Concept: 'https://eisenhorn.com/blog/training/mike5-mit-dem-5-minuten-taeglich-trainingsplan-effizient-zu-deinem-ziel/',
        motivating: 'https://eisenhorn.com/blog/training/the-big-5-motivierendes-big-5-training/',
        intensity: 'https://eisenhorn.com/blog/training/intensitaet-ist-voraussetzung/',
        periodization: 'https://eisenhorn.com/blog/training/periodisierung/',
        efficiency: 'https://eisenhorn.com/blog/training/effizienz-durch-regelmaessigkeit/',
        passwordForgot: 'https://eisenhorn.com/my-account/lost-password/'
    },
    'en': {
        exerciseDatabase: 'https://eisenhorn.com/en/eisenhorn-exercises/',
        mike5Concept: 'https://eisenhorn.com/en/blog/training/mike5-the-efficient-5-minute-daily-training-plan-to-reach-your-goal/',
        motivating: 'https://eisenhorn.com/en/blog/training/the-big-5-motivating-big-5-training/',
        intensity: 'https://eisenhorn.com/en/blog/training/intensity-is-a-prerequisite/',
        periodization: 'https://eisenhorn.com/en/blog/training/periodization/',
        efficiency: 'https://eisenhorn.com/en/blog/training/efficiency-through-regularity/',
        passwordForgot: 'https://eisenhorn.com/en/my-account/lost-password/'
    }
};