import React, { useContext, useMemo, useCallback } from 'react';
import { Context } from "../context";
import { dateToStringZeroTime, getDateOfISOWeek, isEmptyObject } from "../helpers";
import checkIcon from '../assets/images/check_icon.svg';
import crossIcon from '../assets/images/cross_icon.svg';
import { useTranslation } from "react-i18next";

const today = new Date();
today.setHours(0, 0, 0, 0);

const Day = React.memo(({ day, currentDay, completedWorkoutsSet, onDayClick, weekdays, type, formattedDate }) => {
    const isCurrent = day === currentDay;
    const simpleDayDate = dateToStringZeroTime(getDateOfISOWeek(day + 1));
    const isCompleted = completedWorkoutsSet.has(simpleDayDate);
    const isCurrentOrFuture = (day + 1) >= today.getDay();
    const isToday = (day + 1) === today.getDay();
    const isFuture = (day + 1) > today.getDay();

    const getIconForDay = () => {
        if (isCurrentOrFuture) {
            return isCompleted ? checkIcon : null;
        } else {
            return isCompleted ? checkIcon : crossIcon;
        }
    };

    const getContentForDay = () => {
        if (type === 'calendar') {
            return <div className="week-navigation__day-of-month">{formattedDate}</div>;
        } else {
            return (
                <div className="week-navigation__workout-indicator">
                    {getIconForDay() && <img src={getIconForDay()} width="20" alt="" />}
                </div>
            );
        }
    };

    return (
        <div
            className={`week-navigation__day ${isCurrent ? 'is-active' : ''} ${isToday ? 'is-today' : ''} ${isFuture ? 'is-future' : ''} ${isCompleted ? 'is-complete' : ''}`}
            onClick={() => onDayClick(day)}
        >
            <div className="week-navigation__day-name">{weekdays[day]}</div>
            {getContentForDay()}
        </div>
    );
});

const WeekNavigation = React.memo(({ type, workout, mike5Component }) => {
    const { t } = useTranslation();
    const { currentDay, handleSetCurrentDay, userData, activeMike5Workout } = useContext(Context);

    const completedWorkouts = userData && userData['workout_progress'] && userData['workout_progress'].length && !isEmptyObject(userData)
        ? userData['workout_progress']
            .filter(item => {
                // Check if item.workout_id exists and get all language-specific IDs
                const workoutIds = item.workout_id ? Object.values(item.workout_id).map(id => parseInt(id)) : [];
                // Ensure the workout ID to compare is parsed
                const currentWorkoutId = workout ? parseInt(workout.id) : null;

                return workout
                    ? (workoutIds.includes(currentWorkoutId) && (!mike5Component || item.mike5Component === mike5Component))
                    : false;
            })
            .map(item => dateToStringZeroTime(item['intended_completion_date']))
        : [];

    const completedWorkoutsSet = new Set(completedWorkouts);

    const handleSetCurrentDayMemoized = useCallback((day) => {
        handleSetCurrentDay(day);
    }, [handleSetCurrentDay]);

    const firstDayOfWeek = useMemo(
        () => {
            let now = new Date();
            const currentDay = now.getDay(),
                difference = currentDay - 1;  // 1 represents Monday

            if (difference < 0) {
                // If current day is Sunday, setDate to go back 6 days to previous Monday
                now.setDate(now.getDate() - 6);
            } else {
                now.setDate(now.getDate() - difference);
            }
            return now;
        },
        []
    );

    let weekdays = [t('mo'), t('tue'), t('wed'), t('thu'), t('fr')];

    return (
        <div className={`week-navigation week-navigation--${type}`}>
            {weekdays.map((day, index) => {
                const dayOfTheWeek = new Date(firstDayOfWeek.getFullYear(), firstDayOfWeek.getMonth(), firstDayOfWeek.getDate() + index);
                const formattedDate = `${dayOfTheWeek.getDate()}.${dayOfTheWeek.getMonth() + 1}.`;

                return (
                    <Day
                        key={index}
                        day={index}
                        currentDay={currentDay - 1}
                        completedWorkoutsSet={type === 'workout' ? completedWorkoutsSet : new Set()}
                        onDayClick={handleSetCurrentDayMemoized}
                        weekdays={weekdays}
                        type={type}
                        formattedDate={formattedDate}
                    />
                );
            })}
        </div>
    );
});

export default WeekNavigation;