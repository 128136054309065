import useSound from 'use-sound';
import beepSfx from '../assets/audio/beep.mp3';
import workSfx from '../assets/audio/work.mp3';
import workDESfx from '../assets/audio/work-1-de.mp3';
import workDEContinueSfx from '../assets/audio/work-2-de.mp3';
import restSfx from '../assets/audio/rest.mp3';
import restDESfx from '../assets/audio/rest-de.mp3';
import getReadySfx from '../assets/audio/get-ready.mp3';
import getReadyDESfx from '../assets/audio/get-ready-de.mp3';
import nextExerciseSfx from '../assets/audio/next-exercise.mp3';
import nextExerciseDESfx from '../assets/audio/next-exercise-de.mp3';
import workoutCompleteSfx from '../assets/audio/workout_complete.mp3';
import workoutCompleteDESfx from '../assets/audio/workout_complete-de.mp3';
import {useTranslation} from "react-i18next";

const useSfx = () => {
    const { i18n} = useTranslation();

    let selectedWorkSfx = workSfx;
    let selectedRestSfx = restSfx;
    let selectedGetReadySfx = getReadySfx;
    let selectedNextExerciseSfx = nextExerciseSfx;
    let selectedWorkoutCompleteSfx = workoutCompleteSfx;
    let selectedWorkContinue = workDEContinueSfx;

    if (i18n.language === 'de') {
        selectedWorkSfx = workDESfx;
        selectedRestSfx = restDESfx;
        selectedGetReadySfx = getReadyDESfx;
        selectedNextExerciseSfx = nextExerciseDESfx;
        selectedWorkoutCompleteSfx = workoutCompleteDESfx;
    }

    const [playBeep] = useSound(beepSfx);
    const [playWork] = useSound(selectedWorkSfx);
    const [playWorkContinue] = useSound(selectedWorkContinue);
    const [playRest] = useSound(selectedRestSfx);
    const [playGetReady] = useSound(selectedGetReadySfx);
    const [playNextExercise] = useSound(selectedNextExerciseSfx);
    const [playWorkoutComplete] = useSound(selectedWorkoutCompleteSfx);

    return {
        playBeep,
        playWork,
        playWorkContinue,
        playRest,
        playGetReady,
        playNextExercise,
        playWorkoutComplete,
    };
};

export default useSfx;