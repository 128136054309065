/* global Capacitor */

import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import {ContextProvider} from "./context";
import AuthProvider from "./AuthProvider";
import {LocalNotifications, Weekday} from '@capacitor/local-notifications';
import * as Sentry from "@sentry/react";
import QueueProvider from "./QueueProvider";
import i18n from './i18n';
import {I18nextProvider} from "react-i18next";
import NoSleep from 'nosleep.js';

Sentry.init({
    dsn: "https://b7caa4cdc6ef1108f7de2064f0a632f1@o4506835559710720.ingest.sentry.io/4506835561676800",
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration({
            maskAllText: false,
            blockAllMedia: false,
            networkDetailAllowUrls: ['https://eisenhorn.com/wp-json/']
        }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", "mike5.eisenhorn.com", "mike5staging.eisenhorn.com"],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <I18nextProvider i18n={i18n}>
            <AuthProvider>
                <QueueProvider>
                    <ContextProvider>
                        <App />
                    </ContextProvider>
                </QueueProvider>
            </AuthProvider>
        </I18nextProvider>
    </React.StrictMode>
);

if (Capacitor.getPlatform() === 'web') {
    const noSleep = new NoSleep();

    document.addEventListener('click', function enableNoSleep() {
        noSleep.enable()
            .then(() => {
                console.log('NoSleep now active');
            })
            .catch((error) => {
                console.error('Failed to enable NoSleep', error);
            });
        // Remove the event listener after the first activation
        document.removeEventListener('click', enableNoSleep, false);
    }, false);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

if ((await LocalNotifications.requestPermissions()).display === 'granted') {
    let notifications = [
        Weekday.Monday,
        Weekday.Tuesday,
        Weekday.Wednesday,
        Weekday.Thursday,
        Weekday.Friday,
    ]
        .map(weekday => {
            return {
                title: 'Erinnerung: MIKE5 Workout',
                body: 'Guten Morgen! 🌞 Pack dein Training an – kleine Schritte, große Erfolge! 💪',
                id: weekday,
                schedule: {
                    on: {
                        weekday,
                        minute: 0,
                        second: 0,
                        hour: 9,
                    },
                },
            };
        });

    await LocalNotifications.schedule({notifications});

    await LocalNotifications.cancel({
        notifications: [
            { id: Weekday.Saturday },
            { id: Weekday.Sunday },
        ],
    })
}
