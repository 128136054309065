import { useState, useEffect } from 'react';

const useIsMobileSafari = () => {
    const [isMobileSafari, setIsMobileSafari] = useState(false);

    useEffect(() => {
        const userAgent = window.navigator.userAgent.toLowerCase();
        setIsMobileSafari(/iphone|ipad|ipod/.test(userAgent) && /safari/.test(userAgent));
    }, []);

    return isMobileSafari;
}

export default useIsMobileSafari;

